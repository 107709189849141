.custom-calendar.rmdp-wrapper,
.rmdp-container .custom-calendar.ep-arrow::after {
    background-color: white;
}

.rmdp-container .custom-calendar.ep-arrow[direction="bottom"] {
    border-top: 1px solid bisque;
}

.rmdp-container .custom-calendar.ep-arrow[direction="top"] {
    border-bottom: 1px solid bisque;
    border-radius: 8px;
}

.rmdp-header {
    background-color: #F2E4EF;
    border-radius: 6px 6px 0 0;
    margin-top: 0;
    color: #92395C;
}

.rmdp-calendar{
    padding: 0;
    width: 100%;
}

.rmdp-day-picker{
    padding: 16px;
}




:root {
    --rmdp-primary-purple: #8B6683; /* Default purple */
    --rmdp-secondary-purple: #F2E4EF; /* Light purple */
    --rmdp-shadow-purple: #F2E4EF; /* Same as light purple */
    --rmdp-today-purple: #501A46; /* Dark purple */
    --rmdp-hover-purple: #8B6683; /* Default purple for hover */
    --rmdp-deselect-purple: #501A46; /* Dark purple for deselect */
}


.purple .rmdp-wrapper {
    border: 1px solid var(--rmdp-secondary-purple);
    box-shadow: 0 0 5px var(--rmdp-secondary-purple);
}

.purple .rmdp-panel-body li {
    background-color: var(--rmdp-primary-purple);
    box-shadow: 0 0 2px var(--rmdp-secondary-purple);
}

.purple .rmdp-week-day {
    color: var(--rmdp-primary-purple);
}

.purple .rmdp-day.rmdp-deactive {
    color: var(--rmdp-secondary-purple);
}

.purple .rmdp-range {
    background-color: var(--rmdp-primary-purple);
    box-shadow: 0 0 3px var(--rmdp-shadow-purple);
}

.purple .rmdp-arrow {
    border: solid var(--rmdp-primary-purple);
    border-width: 0 2px 2px 0;
}

.purple .rmdp-arrow-container:hover {
    background-color: var(--rmdp-primary-purple);
    box-shadow: 0 0 3px var(--rmdp-secondary-purple);
}

.purple .rmdp-panel-body::-webkit-scrollbar-thumb {
    background: var(--rmdp-primary-purple);
}

.purple .rmdp-day.rmdp-today span {
    background-color: var(--rmdp-today-purple);
}

.purple .rmdp-rtl .rmdp-panel {
    border-left: unset;
    border-right: 1px solid var(--rmdp-secondary-purple);
}

.purple .rmdp-day.rmdp-selected span:not(.highlight) {
    background-color: var(--rmdp-primary-purple);
    box-shadow: 0 0 3px var(--rmdp-shadow-purple);
}

.purple .rmdp-day:not(.rmdp-day-hidden):not(.rmdp-disabled):hover span {
    background-color: var(--rmdp-hover-purple) !important;
    color: white; /* Optional: to make text readable on hover */
}


.purple .b-deselect {
    color: var(--rmdp-deselect-purple);
    background-color: white;
}

.purple .rmdp-action-button {
    color: var(--rmdp-primary-purple);
}

.purple .rmdp-button:not(.rmdp-action-button) {
    background-color: var(--rmdp-primary-purple);
}

.purple .rmdp-button:not(.rmdp-action-button):hover {
    background-color: var(--rmdp-deselect-purple);
}
