@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'shabnam';
  src: url('./assets/fonts/Shabnam-FD.woff') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

body {
  margin: 0;
  font-family: shabnam, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Hide arrows in Chrome, Safari, and newer Edge */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Hide arrows in Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
